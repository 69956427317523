import React from "react";

export const DashboardIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/dashboard.svg"
                alt="dashboard"
                width="20"
                height="20"
            />
        </span>
    );
};

export const ProjectIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/project.svg"
                alt="dashboard"
                width="20"
                height="20"
            />
        </span>
    );
};

export const ApiKeyIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/api-key.svg"
                alt="dashboard"
                width="20"
                height="20"
            />
        </span>
    );
};

export const RoutingIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/routing.svg"
                alt="dashboard"
                width="20"
                height="20"
            />
        </span>
    );
};

export const RateLimitConfigurationIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/limit.png"
                alt="dashboard"
                width="20"
                height="20"
            />
        </span>
    );
};

export const PaymentSettingIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/payment-setting.svg"
                alt="dashboard"
                width="20"
                height="20"
            />
        </span>
    );
};

export const ApiIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img src="/icons/menu/api.svg" alt="api" width="20" height="20" />
        </span>
    );
};

export const PaymentIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/payment.svg"
                alt="payment"
                width="20"
                height="20"
            />
        </span>
    );
};
export const RefundIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/refund.svg"
                alt="payment"
                width="20"
                height="20"
            />
        </span>
    );
};
export const OrderIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/order-transaction.svg"
                alt="payment"
                width="20"
                height="20"
            />
        </span>
    );
};
export const UsageReportIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/usage-report.svg"
                alt="payment"
                width="20"
                height="20"
            />
        </span>
    );
};
export const DeliveryIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/delivery.svg"
                alt="Delivery"
                width="20"
                height="20"
            />
        </span>
    );
};
export const OtherTransactionIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/orther-transaction.svg"
                alt="orthertransaction"
                width="20"
                height="20"
            />
        </span>
    );
};

export const AlarmIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/alarm.svg"
                alt="alarm"
                width="20"
                height="20"
            />
        </span>
    );
};

export const SearchIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/search.svg"
                alt="alarm"
                width="20"
                height="20"
            />
        </span>
    );
};

export const LogsIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img src="/icons/menu/log.svg" alt="logs" width="20" height="20" />
        </span>
    );
};

export const BillingsIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/biling.svg"
                alt="bill"
                width="20"
                height="20"
            />
        </span>
    );
};

export const UserIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/user.svg"
                alt="bill"
                width="20"
                height="20"
            />
        </span>
    );
};

export const CatalogIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/menu/catalog.svg"
                alt="bill"
                width="20"
                height="20"
            />
        </span>
    );
};

//icons service
export const VisaIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/visa.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};

export const DomesticIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/domestic.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};

export const MomoIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/momo.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};

export const ZaloIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/zalo.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};

export const ShopeeIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/shopee.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};

export const GrabIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/grab.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};
export const ViettelIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/viettel.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};

export const DCPAYIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/dc-pay.png"
                alt="bill"
                width="30"
            />
        </span>
    );
}

export const AlliexIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/alliex.svg"
                alt="bill"
                width="30"
                height="30"
            />
        </span>
    );
};


export const VNPTDCPAYIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/vnpt-pay.png"
                alt="bill"
                width="30"
            />
        </span>
    );
}

export const VNPAYIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/vnpay.png"
                alt="bill"
                width="30"
            />
        </span>
    );
}

export const VNPAYQRIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/vnpay-qr.png"
                alt="bill"
                width="30"
            />
        </span>
    );
}

export const VNPTMegapayIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/vnpt-megapay.webp"
                alt="bill"
                width="30"
            />
        </span>
    );
}

export const LotteCnFBNPLIcon: React.FC = () => {
    return (
        <span className="anticon anticon-pie-chart ant-menu-item-icon">
            <img
                src="/icons/service/lotteCnFBNPL.webp"
                alt="bill"
                width="30"
            />
        </span>
    );
}